var Utils = require("./utils");
var queryArray = Utils.queryArray;

function startRotator() {
  queryArray("section.hero .banner")[0].classList.remove("override-slide");
  var activeSlide = document.querySelector("section.hero .banner.active");
  var nextSlide = activeSlide.nextElementSibling;

  if (nextSlide) {
  } else {
    nextSlide = queryArray("section.hero .banner")[0];
  }

  var slides = queryArray("section.hero .banner");
  slides.forEach(function(item){
    item.classList.remove("inactive");
  });
  activeSlide.classList.add("inactive");
  activeSlide.classList.remove("active");
  nextSlide.classList.add("active");
}

// Make initial one active
var rotator = setInterval(startRotator, 6000);
document.querySelector("section.hero").addEventListener("mouseover", function() {
  clearInterval(rotator);
});
document.querySelector("section.hero").addEventListener("mouseleave", function() {
  rotator = setInterval(startRotator, 6000);
});